<template>
  <select ref="combo">
    <slot></slot>
  </select>
</template>

<script>
  import $ from 'jquery'
  import 'select2'
  import 'select2/dist/css/select2.min.css'

  export default {
    props: ['value'],
    mounted() {
      $(this.$refs.combo)
        .select2({
          minimumResultsForSearch: -1
        })
        .val(this.value)
        .trigger('change')
        .on('change', (event) => {
          this.$emit('input', event.target.value);
        });
    },
    beforeDestroy() {
      $(this.$refs.combo).css('opacity', 0)
      $(this.$refs.combo).data('select2').destroy()
    },
  }
</script>

<style lang="scss">
  .select2 {
    width: 100% !important;
    &-container--default {
      .select2-results>.select2-results__options {
        max-height: 295px;
      }
      .select2-selection--single {
        height: 40px;
        background-color: #272C30;
        border: none {
          radius: 3px;
        }
        .select2-selection__rendered {
          color: #ffffff;
          line-height: 40px;
          padding-left: 16px;
          padding-right: 36px;
        }
        .select2-selection__arrow {
          width: 36px;
          height: 38px;
          b {
            width: 9px;
            height: 6px;
            background: url(./select-arrow.svg) 0 0/9px 6px no-repeat;
            border: none;
          }
        }
      }
      .select2-results__option {
        padding-left: 16px;
        padding-right: 16px;
        &--highlighted[aria-selected] {
          background-color: #C70552;
        }
        &[aria-selected=true] {
          background-color: #C70552;
        }
        &:last-child {
          border-radius: 0 0 3px 3px;
        }
      }
    }
    &.select2-container--open {
      .select2-selection--single {
        .select2-selection__arrow {
          b {
            transform: rotate(-180deg);
          }
        }
      }
    }
    &-dropdown {
      background-color: #272c30;
      color: #ffffff;
      border: none;
    }
  }
</style>
