<template>
  <div class="overlay overlay--scrollable">
    <div class="overlay__fixed">
      <page-title>{{i18n.MENU.model}}</page-title>
      <div class="models-space">
        <select2
          :value="series"
          :key="JSON.stringify(doorSeries)"
          @input="setSeries($event)"
        >
          <option
            value=""
            :selected="series === ''"
          >{{i18n.SELECT_DOOR_SERIES}}</option>
          <option
            v-for="(item, idx) in doorSeries"
            :key="`series_${idx}`"
            :checked="series === item.url"
            :value="item.url"
          >
            {{ item.title }}
          </option>
        </select2>
      </div>
    </div>
    <div class="overlay__scroll">
      <models
        :series="series"
        :key="series"
        v-if="series !== ''"
      />
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/page-title/page-title.vue'
  import Select2 from '@/components/shared/select2/select2.vue'
  import Models from '@/components/model/models.vue'
  import { mapGetters } from 'vuex'
  import axios from "axios";
  import {API} from "@/constants/api";

  export default {
    data() {
      return {
        doorSeries: null,
      }
    },
    computed: {
      ...mapGetters('config', [
        'series',
      ]),
      ...mapGetters('session', [
        'i18n',
      ]),
    },
    components: {
      PageTitle,
      Select2,
      Models,
    },
    metaInfo() {
      return {
        title: this.i18n.MENU.model,
        meta: [
          { vmid: 'description', property: 'description', content: 'Модель' },
          { vmid: 'og:title', property: 'og:title', content: 'Модель' },
          { vmid: 'og:description', property: 'og:description', content: 'Модель' },
        ],
      }
    },
    methods: {
      setSeries(series) {
        return this.$store.dispatch('config/CONFIG_SET_SERIES', series)
      }
    },
    created() {
      axios
        .get(API.GET_MODEL_SERIES)
        .then(response => {
          this.doorSeries = response.data;
          // this.$store.dispatch('config/CONFIG_SET_SERIES', this.doorSeries[0].url);
        });
    }
  }
</script>

<style lang="scss">
.models-space {
  padding: 30px;
}
</style>
